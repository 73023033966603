<!--
 * @Description:财务综合管理 财务管理 支付对账 onroadPatternDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-19 14:37:42
 * @LastEditors: zhoucheng
-->
<template>
  <div class="dt-onroadPatternDetails">
    <el-row class="dt-back">
      <i class="el-icon-back"
         @click="handleBack"></i>
      <span>对账细则</span>
    </el-row>
    <el-row class="topSelect">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="支付渠道">
              <el-select v-model="searchForm.payType"
                         placeholder="请选择"
                         clearable>
                <el-option v-for="item in paymentOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="对账结果">
              <el-select v-model="searchForm.checkState"
                         placeholder="请选择"
                         clearable>
                <el-option v-for="item in checkStateList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="处理状态">
              <el-select v-model="searchForm.dealState"
                         placeholder="请选择"
                         clearable>
                <el-option v-for="item in dealStateList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>支付对账</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :paginationConfig='paginationConfig'
                  :tableConfig='tableConfig'
                  @getList="getList">
          <!-- <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClick(scope.row)"
                             type="text"
                             size="medium">详情</el-button>
                </el-col>
                <el-col :span="8"
                        v-if="scope.row.dealState!=0">
                  <el-button type="text"
                             size="medium"
                             @click="handleClickHandle">处理</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template> -->
        </dt-table>
      </el-row>
    </el-row>

  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'checkId',
          label: '对账编号',
        },
        {
          prop: 'checkTime',
          label: '对账时间',
        },
        {
          prop: 'orderSequence',
          label: '订单编号',
        },
        {
          prop: 'payTypeName',
          label: '支付渠道',
        },
        {
          prop: 'money',
          label: '对账金额（元）',
        },
        {
          prop: 'checkStateName',
          label: '对账结果',
        },
        {
          prop: 'payId',
          label: '支付记录编号',
        },
        {
          prop: 'payTime',
          label: '订单支付时间',
        },
        {
          prop: 'dealStateName',
          label: '是否处理',
        },

      ],
      tableList: {
        list: [],
      },
      pageNum: 1,
      pageSize: 15,
      //查询
      searchForm: {},
      orderTime: null,
      //支付渠道
      paymentOptions: [],
      //对账结果
      checkStateList: [],
      //处理状态
      dealStateList: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
    this.getPayType()
    this.getCheckState()
    this.getDealState()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 导出
    exportFile () {
      this.$axios.post(this.$downloadOrderBaseUrl + "checkDetail/export", { "parentId": this.$route.query.parentId }, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }
      ).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "支付对账.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    //初始化表格数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.searchForm['parentId'] = this.$route.query.parentId
      this.$onroadPatternDetails.list(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.content
        this.paginationConfig.total = res.resultEntity.totalElements
        this.tableList.list.forEach(item => {
          item.money = (item.money / 100).toFixed(2)
        });
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //查询
    onSubmit () {
      this.pageNum = 1
      this.queryTableList()
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.pageNum = 1
      this.queryTableList()
    },
    //返回
    handleBack () {
      this.$router.go(-1)
    },
    // 处理
    handleClickHandle () {

    },
    // 支付渠道下拉
    getPayType () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '58184EF53AA9411AB1A2F49E6DC3D223',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.paymentOptions = response.resultEntity
      }).catch()
    },
    // 对账结果下拉
    getCheckState () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'ddf4f90b-26b8-4312-bdb9-92691b2c8082',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.checkStateList = response.resultEntity
      }).catch()
    },
    // 处理状态下拉
    getDealState () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '61d917de-85f6-41f1-977e-d655688eb839',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.dealStateList = response.resultEntity
      }).catch()
    },
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;
.dt-onroadPatternDetails {
  height: 100%;
  .dt-back {
    color: #bae8ff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .topSelect {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>